

























































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'

@Component({
  components: {
    Breadcrumb
  }
})
export default class Services extends Vue {
  params: URLSearchParams = new URLSearchParams(
    location.href.split('?')[1] || ''
  )
  nowDate: Date = new Date(this.params.get('dt') || Date.now())
}
