










































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getDotDate } from '@/lib/dateModules'
import CocokabuHeader from '@/components/CocokabuHeader.vue'
import CocokabuFooter from '@/components/CocokabuFooter.vue'

@Component({
  components: {
    CocokabuHeader,
    CocokabuFooter
  }
})
export default class LP extends Vue {
  getDotDate(yymmdd_nn: string): string {
    return getDotDate(yymmdd_nn)
  }
}
