


























































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'
import PressList from '@/components/PressList.vue'
import PressObj from '@/interfaces/PressObj'
import UrgentObj from '@/interfaces/UrgentObj'
import { getDotDate } from '@/lib/dateModules'
import { getTopicList, getUrgentList } from '@/lib/listModules'

@Component({
  components: {
    Breadcrumb,
    PressList
  }
})
export default class Top extends Vue {
  topicsList: Array<PressObj> = []
  urgentList: Array<UrgentObj> = []
  params: URLSearchParams = new URLSearchParams(
    location.href.split('?')[1] || ''
  )
  nowDate: Date = new Date(this.params.get('dt') || Date.now())

  async mounted(): Promise<void> {
    this.topicsList = await getTopicList()
    this.urgentList = await getUrgentList()
  }

  getDotDate(yymmdd_nn: string): string {
    return getDotDate(yymmdd_nn)
  }
}
